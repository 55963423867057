import { FC, useState, useEffect, useMemo, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BasicModal from "../BasicModal";
import { Fade } from "@mui/material";
import {
  CloseModalButton,
  CurrencyButton,
  MainButton,
} from "components/Buttons";
import { Select } from "components/Inputs";
import { SelectItemProps } from "components/Inputs/types";
import { AppText, Preloader, Tabs } from "components";
import {
  ModalContent,
  ModalHeading,
  MainContainer,
  TransactionGroup,
  InnerContent,
} from "../styled";
import { colors } from "helpers/consts";
import { CurrenciesStore, WalletStore, UserStore, GlobalStore } from "stores";
import { createWallet, createWalletAddress } from "api/wallet";
import { getFoxpayBankAccDetails } from "api/foxpay";
import { socket } from "api/socket";
import PaymentSelect from "../PaymentSelect";
import { ModalWarningInfo } from "components/Modals";
import SuccessDepositStep from "./SuccessDepositStep";
import MainTab from "./MainTab";
import MetaMaskTab from "./MetaMaskTab";
import type {
  AvailableTradeOptions,
  WalletsListInterface,
  WalletsAddressInterface,
  CurrencyInterface,
  ModalStepsType,
  // Timer,
} from "helpers/types";
import type { CreateNewAddressRes } from "api/types/wallet";
import type { FoxpayGetBankAccRes } from "api/types/foxpay";

// let timeOut: Timer;

// export const bankVariant: CurrencyInterface = {
//   code: "EUR",
//   depositEnabled: true,
//   depositFee: 0,
//   networkName: "EUR",
//   networkCode: "EUR",
//   maxDeposit: 1000000,
//   maxWithdraw: 1000000,
//   minDeposit: 0,
//   minWithdraw: 0,
//   name: "EUR",
//   precision: 8,
//   sign: "EUR",
//   text: "EUR",
//   coin: "EUR",
//   withdrawEnabled: true,
//   withdrawFee: 10,
// };

const tabs = ["MAIN", "MetaMask"];

interface DepositModalProps {
  isOpen: boolean;
  onClose: () => void;
  initialVariant: AvailableTradeOptions;
  isFiat?: boolean;
}

const DepositModal: FC<DepositModalProps> = ({
  isOpen,
  onClose,
  initialVariant,
  isFiat = false,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState<ModalStepsType>("main");
  const { currencies, prices } = CurrenciesStore;
  const { walletsList, addWalletAddress } = WalletStore;
  const {
    user: { level },
  } = UserStore;

  const [tabValue, setTabValue] = useState<number>(0);
  const [chosenNetwork, setChosenNetwork] = useState<string>("");
  // const [tooltipiIsOpen, setTooltipIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNewAddressLoading, setIsNewAddressLoading] =
    useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [addNewAddressError, setAddNewAddressError] = useState<boolean>(false);
  const [bankAccountDetails, setBankAccountDetails] =
    useState<FoxpayGetBankAccRes | null>(null);
  const [paymentType, setPaymentType] = useState<CurrencyInterface | undefined>(
    undefined
  );

  const closeSelect = () => setIsSelectOpen(false);
  const openSelect = () => setIsSelectOpen(true);

  // const closeTooltip = () => {
  //   setTooltipIsOpen(false);
  // };

  const onSelectNetwork = useCallback(
    (networkValue: string) => {
      if (
        currencies &&
        paymentType &&
        networkValue !== paymentType?.networkCode
      ) {
        setPaymentType(
          currencies.find(
            ({ networkCode, name }) =>
              paymentType.name === name && networkCode === networkValue
          )
        );
      }
      setAddNewAddressError(false);
      setChosenNetwork(networkValue || "");
    },
    [currencies, paymentType]
  );

  // const handleCopy = () => {
  //   if (paymentType?.code !== "EUR" && !walletAddress) {
  //     return;
  //   }
  //   if (paymentType?.code !== "EUR" && walletAddress) {
  //     navigator.clipboard.writeText(walletAddress);
  //   }
  //   if (paymentType?.code === "EUR") {
  //     navigator.clipboard.writeText(testIban);
  //   }
  //   navigator.clipboard.writeText(testDeposit);
  //   setTooltipIsOpen(true);
  // };
  const onPaymentChange = (option: CurrencyInterface) => {
    onSelectNetwork("");
    setBankAccountDetails(null);
    setPaymentType(option);
  };

  const currentWallet = useMemo<WalletsListInterface | undefined>(() => {
    return walletsList?.find(
      ({ currencyName }) => currencyName === paymentType?.name
    );
  }, [walletsList, paymentType]);

  const walletAddress = useMemo<string | undefined>(() => {
    if (paymentType?.name && currentWallet && chosenNetwork) {
      return currentWallet.addresses.find(
        ({ networkCode }) => networkCode === chosenNetwork
      )?.address;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletsList, paymentType, chosenNetwork, currentWallet]);

  useEffect(() => {
    const createNewWallet = async () => {
      setIsLoading(true);
      await createWallet(paymentType!.name);
      setIsLoading(false);
    };
    if (paymentType?.name && !currentWallet) {
      createNewWallet();
    }
  }, [paymentType, currentWallet]);

  useEffect(() => {
    if (level === "SENIOR" && paymentType?.name === "EUR") {
      setIsLoading(true);
      getFoxpayBankAccDetails(paymentType.name)
        .then(({ data }) => setBankAccountDetails(data))
        .catch((err) => {
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        })
        .finally(() => setIsLoading(false));
    }
  }, [paymentType, level]);

  useEffect(() => {
    if (
      isOpen &&
      currentWallet &&
      !chosenNetwork &&
      paymentType?.networkCode &&
      currencies?.filter((el) => el.name === paymentType?.name)?.length === 1
    ) {
      onSelectNetwork(paymentType?.networkCode);
    }
  }, [
    paymentType,
    currentWallet,
    chosenNetwork,
    currencies,
    isOpen,
    onSelectNetwork,
  ]);

  const addNewWalletAddress = useCallback(
    (data: CreateNewAddressRes) => {
      if (data.status === "REQUESTED") {
        return;
      }
      if (data.address && data.status === "SUCCESS") {
        const newWalletAddress: WalletsAddressInterface = {
          address: data.address!,
          currencyName: data.currencyName,
          memo: data.memo,
          networkCode: data.networkCode,
          networkName: data.networkName,
        };
        addWalletAddress(newWalletAddress, data.currencyName);
      }
      if (data.status === "ERROR") {
        setAddNewAddressError(true);
        GlobalStore.setError(
          "Sorry, unknown error occurred and address for this currency haven't created"
        );
      }
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
      setIsNewAddressLoading(false);
    },
    [addWalletAddress]
  );

  const createNewWalletAddress = useCallback(async () => {
    if (paymentType?.name && currentWallet && chosenNetwork && !walletAddress) {
      setAddNewAddressError(false);
      setIsNewAddressLoading(true);
      socket.on("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);

      await createWalletAddress(paymentType?.networkCode!, paymentType?.name!)
        .then(({ data }) => addNewWalletAddress(data))
        .catch((err) => {
          socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
          setIsNewAddressLoading(false);
          if (err?.response?.data?.message) {
            GlobalStore.setError(err.response.data.message);
          }
        });
    }
  }, [
    chosenNetwork,
    paymentType?.name,
    currentWallet,
    walletAddress,
    paymentType?.networkCode,
    addNewWalletAddress,
  ]);

  useEffect(() => {
    createNewWalletAddress();
  }, [createNewWalletAddress]);

  useEffect(() => {
    return () => {
      socket.off("DEPOSIT_ADDRESS_REQUEST_UPDATE", addNewWalletAddress);
    };
  }, [addNewWalletAddress]);

  // useEffect(() => {
  //   if (tooltipiIsOpen) {
  //     timeOut = setTimeout(() => closeTooltip(), 1500);
  //   }

  //   return () => clearTimeout(timeOut);
  // }, [tooltipiIsOpen]);

  useEffect(() => {
    if (isOpen && currencies) {
      // if (isFiat) {
      //   setPaymentType(bankVariant);
      //   return;
      // }
      currencies &&
        setPaymentType(currencies.find(({ name }) => name === initialVariant)!);
    }
  }, [initialVariant, isFiat, currencies, isOpen]);

  useEffect(() => {
    if (isOpen && currencies && tabValue === 1 && paymentType?.name !== "ETH") {
      setPaymentType(currencies.find(({ name }) => name === "ETH")!);
    }
  }, [tabValue, paymentType, isOpen, currencies]);

  useEffect(() => {
    if (isOpen && tabValue === 1 && chosenNetwork !== "ETH") {
      setChosenNetwork("ETH");
    }
  }, [tabValue, chosenNetwork, isOpen]);

  const currenciesOptions = useMemo<CurrencyInterface[] | null>(() => {
    if (currencies) {
      return currencies.reduce(
        (acc: CurrencyInterface[], el: CurrencyInterface) => {
          if (el.depositEnabled && !acc.find(({ name }) => name === el.name)) {
            acc.push(el);
          }
          return acc;
        },
        []
      );
    }
    return null;
  }, [currencies]);

  const networkOptions = useMemo<Array<SelectItemProps> | undefined>(() => {
    if (currencies && paymentType && tabValue === 1) {
      return currencies
        .filter(
          ({ name, networkCode }) => name === "ETH" && networkCode === "ETH"
        )
        .map((el) => ({
          value: el.networkCode,
          label: el.networkName,
        }));
    }
    if (currencies && paymentType) {
      return currencies
        .filter(({ name }) => name === paymentType.name)
        .map((el) => ({
          value: el.networkCode,
          label: el.networkName,
        }));
    }
    return undefined;
  }, [currencies, paymentType, tabValue]);

  const resetState = useCallback(() => {
    setStep("main");
    setPaymentType(undefined);
    onSelectNetwork("");
    setBankAccountDetails(null);
    setAddNewAddressError(false);
    setIsLoading(false);
    setIsNewAddressLoading(false);
    setTabValue(0);
  }, [onSelectNetwork]);

  return (
    <BasicModal
      isOpen={isOpen}
      onClose={onClose}
      onExited={resetState}
      title={t("DEPOSIT")}
    >
      {step === "main" && (
        <Fade in timeout={500}>
          <ModalContent>
            {(isLoading || isNewAddressLoading) && (
              <Preloader
                isStatic
                text={
                  isNewAddressLoading
                    ? t("NEW_WALLET_ADDRESS_CREATING")
                    : undefined
                }
              />
            )}
            <CloseModalButton onClose={onClose} />
            <ModalHeading>{t("DEPOSIT")}</ModalHeading>
            {process.env.REACT_APP_ENVIRONMENT === "dev" && (
              <Tabs
                tabs={tabs}
                value={tabValue}
                setValue={setTabValue}
                tabsLabel="trade tabs"
              />
            )}
            <InnerContent>
              <MainContainer>
                <TransactionGroup>
                  {tabValue !== 1 && (
                    <AppText color={colors.gray_500}>
                      {t("CHOOSE_METHOD_ASSET")}
                    </AppText>
                  )}
                  <CurrencyButton
                    value={paymentType?.name}
                    icon={paymentType?.name}
                    onClick={openSelect}
                    disabled={tabValue === 1}
                  />
                </TransactionGroup>

                {networkOptions && paymentType?.name !== "EUR" && (
                  <TransactionGroup>
                    <Select
                      variants={networkOptions}
                      value={chosenNetwork}
                      setValue={onSelectNetwork}
                      label={t("SELECT_NETWORK")}
                    />
                  </TransactionGroup>
                )}
              </MainContainer>

              {addNewAddressError && (
                <ModalWarningInfo
                  text={t("ERROR_CREATING_ADDRESS")}
                  fontSize={16}
                  button={
                    <MainButton
                      variant="contained"
                      onClick={createNewWalletAddress}
                    >
                      {t("TRY_AGAIN")}
                    </MainButton>
                  }
                />
              )}

              {tabValue === 0 && (
                <MainTab
                  prices={prices}
                  walletsList={walletsList}
                  chosenNetwork={chosenNetwork}
                  bankAccountDetails={bankAccountDetails}
                  isLoading={isLoading}
                  paymentType={paymentType}
                  currentWallet={currentWallet}
                  walletAddress={walletAddress}
                  level={level}
                />
              )}

              {tabValue === 1 && (
                <MetaMaskTab
                  walletAddress={walletAddress}
                  currencies={currencies}
                  setStep={setStep}
                  isOpen={isOpen}
                  paymentType={paymentType}
                  chosenNetwork={chosenNetwork}
                />
              )}
            </InnerContent>

            <PaymentSelect
              onClose={closeSelect}
              isOpen={isSelectOpen}
              selected={paymentType}
              options={currenciesOptions}
              setValue={onPaymentChange}
              isDeposit
            />
          </ModalContent>
        </Fade>
      )}
      {step === "success" && <SuccessDepositStep onClose={() => onClose()} />}
    </BasicModal>
  );
};

export default observer(DepositModal);
