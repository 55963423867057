import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { colors } from "helpers/consts";
import { ModalButton } from "components/Buttons";
import { AppText, Accordion, CardImage } from "components";
import { ModalRow } from "components/Rows";
import {
  StatusContent,
  StatusDecorative,
  StatusIconContainer,
  InfoColumn,
} from "../styled";
import { InfoRows } from "components/Modals/styled";
import { ExternalHtml } from "pages/CryptoCardsPage/styled";
import { toLocaleStringWithCurrencyNoDecimals } from "helpers/funcs";
import type { LimitsValueInterface, CreditcoCardLevel } from "helpers/types";

import { ReactComponent as HourGlasskIcon } from "assets/icons/hourglass-status.svg";

interface SuccessPurchaseStepProps {
  cardImage: string;
  brandName: string;
  productDescription?: string;
  productHowToUse?: string;
  productRermsAndConditions?: string;
  onClose: () => void;
  isCrypto?: boolean;
  TransactionInfos: ReactNode;
  cashbackPercent?: string;
  topUpFees?: string;
  withdrawFees?: string;
  limitsInfo?: LimitsValueInterface | null;
  level?: CreditcoCardLevel;
}

const SuccessPurchaseStep: FC<SuccessPurchaseStepProps> = ({
  cardImage,
  brandName,
  productDescription,
  productHowToUse,
  productRermsAndConditions,
  onClose,
  TransactionInfos,
  isCrypto,
  cashbackPercent,
  topUpFees,
  withdrawFees,
  limitsInfo,
  level,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in timeout={500}>
      <StatusContent>
        <StatusDecorative>
          <CardImage src={cardImage} alt={brandName} />
          <StatusIconContainer $isPending>
            <HourGlasskIcon />
          </StatusIconContainer>
        </StatusDecorative>
        <InfoColumn>
          <AppText
            fontSize={20}
            fontWeight={600}
            color={colors.gray_600}
            lineHeight={30}
          >
            {brandName}
          </AppText>
          <AppText fontSize={16} fontWeight={400} color={colors.gray_700}>
            {t("CRYPTO_CARD_ACTIVATE")}
          </AppText>
          {isCrypto && (
            <>
              {cashbackPercent && Number(cashbackPercent) > 0 && (
                <InfoRows>
                  <ModalRow
                    keyText={t("CASHBACK")}
                    value={`${Number(cashbackPercent) * 100}%`}
                  />
                </InfoRows>
              )}
              {(topUpFees || withdrawFees) && (
                <Accordion label={t("FEES")}>
                  {topUpFees && (
                    <ModalRow keyText={t("TOP_UP_FEES")} value={topUpFees} />
                  )}
                  {withdrawFees && (
                    <ModalRow
                      keyText={t("WITHDRAW_FEES")}
                      value={withdrawFees}
                    />
                  )}
                  <ModalRow keyText={t("TRANSACTION_FEES")} value="0%" />
                </Accordion>
              )}
              {limitsInfo && (
                <Accordion label={t("LIMITS")}>
                  {/* <ModalRow
                    keyText={t("DAILY_TOP_UPS")}
                    value={toLocaleStringWithCurrencyNoDecimals(
                      limitsInfo.day,
                      limitsInfo.currencyName,
                      "noCode"
                    )}
                  /> */}
                  <ModalRow
                    keyText={t("MONTHLY_TOP_UPS")}
                    value={toLocaleStringWithCurrencyNoDecimals(
                      limitsInfo.month,
                      limitsInfo.currencyName,
                      "noCode"
                    )}
                  />
                  <ModalRow
                    keyText={t("MONTHLY_TRANSACTIONS")}
                    value={toLocaleStringWithCurrencyNoDecimals(
                      level === "GOLD" ? 50000 : 20000,
                      "EUR",
                      "noCode"
                    )}
                  />
                  {/* <ModalRow
                    keyText={t("YEARLY_TOP_UPS")}
                    value={toLocaleStringWithCurrencyNoDecimals(
                      limitsInfo.year,
                      limitsInfo.currencyName,
                      "noCode"
                    )}
                  /> */}
                </Accordion>
              )}
            </>
          )}

          {TransactionInfos}
          {!isCrypto && (
            <>
              {productDescription && (
                <Accordion label={t("OVERVIEW")}>
                  <ExternalHtml
                    dangerouslySetInnerHTML={{ __html: productDescription }}
                  />
                </Accordion>
              )}
              {productHowToUse && (
                <Accordion label={t("HOW_TO_USE")}>
                  <ExternalHtml
                    dangerouslySetInnerHTML={{ __html: productHowToUse }}
                  />
                </Accordion>
              )}
              {productRermsAndConditions && (
                <Accordion label="Terms and conditions">
                  <ExternalHtml
                    dangerouslySetInnerHTML={{
                      __html: productRermsAndConditions,
                    }}
                  />
                </Accordion>
              )}
            </>
          )}

          <ModalButton onClick={onClose}>{t("CONTINUE")}</ModalButton>
        </InfoColumn>
      </StatusContent>
    </Fade>
  );
};

export default SuccessPurchaseStep;
