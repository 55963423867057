import { FC, ReactNode } from "react";
import { AppText } from "components";
import styled from "styled-components";
import { colors, currencyIcons } from "helpers/consts";

import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface CoinCellProps {
  coin: string;
  icon?: ReactNode;
  text: string;
  subText?: string;
  minWidth?: string;
  darkTheme?: boolean;
}

const CoinCell: FC<CoinCellProps> = ({
  coin,
  text,
  subText,
  minWidth,
  icon,
  darkTheme,
}) => {
  return (
    <RowCoinCell $minWidth={minWidth}>
      {icon ? (
        icon
      ) : coin === "empty" ? (
        <EmptyCoin />
      ) : currencyIcons[coin] ? (
        currencyIcons[coin]
      ) : (
        <NAIcon />
      )}
      <RowInfoCell>
        <AppText color={darkTheme ? colors.gray_200 : colors.gray_900}>
          {text}
        </AppText>
        {subText && (
          <AppText isSpan color={colors.gray_600} fontWeight={400}>
            {subText}
          </AppText>
        )}
      </RowInfoCell>
    </RowCoinCell>
  );
};

interface RowCoinCellProps {
  $minWidth?: string;
}
const RowCoinCell = styled.div<RowCoinCellProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth};`}

  & > *:not(:first-child) {
    margin-left: 1.2rem;
  }

  svg {
    min-width: 4.8rem;
    max-width: 4.8rem;
    min-height: 4.8rem;
    max-height: 4.8rem;
  }

  @media (min-width: 36em) {
    & > *:not(:first-child) {
      margin-left: 2rem;
    }
  }
`;

const RowInfoCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

const EmptyCoin = styled.div`
  min-width: 4.8rem;
  min-height: 4.8rem;
  max-height: 4.8rem;
  max-width: 4.8rem;
`;

export default CoinCell;
