import axios from "axios";
import {
  UserStore,
  GlobalStore,
  CardsStore,
  WalletStore,
  StakesStore,
} from "stores";
import { notVerifiedMessage, twoFAMessage } from "helpers/consts";
// import { flattenObject } from "helpers/funcs";
import type {
  UserInterface,
  // TelegramWebAppData
} from "helpers/types";
import type {
  RegisterCredentials,
  LoginCredentials,
  VerifyEmailCredentials,
  ChangePasswordCredentials,
  CondirmEmailCredentials,
  JuniorPersonalData,
  ReferralResponse,
  Add2FaVerificationResponse,
  Toggle2FaVerificationData,
  ChangePasswordReq,
} from "./types/user";

export const token = {
  set(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("access", token);
  },
  unset() {
    axios.defaults.headers.common.Authorization = "";
    localStorage.removeItem("access");
  },
  setCompanyToken(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem("companyAccess", token);
  },
};

export const register = async (credentials: RegisterCredentials) => {
  GlobalStore.startLoading();
  return axios
    .post("/user/register", credentials)
    .then(({ data }) => {
      if (data.token) {
        localStorage.setItem("access", data.token);
      }
      return true;
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    })
    .finally(() => GlobalStore.endLoading());
};

export const logIn = async (credentials: LoginCredentials) => {
  GlobalStore.startLoading();
  return axios
    .post("/user/login", credentials)
    .then(({ data }) => {
      if (data?.emailVerified === false) {
        GlobalStore.setError("email not verified");
        return notVerifiedMessage;
      }
      token.set(data.token);
      UserStore.setAuthenticated(data);
    })
    .catch((err) => {
      if (err?.response?.data?.message === twoFAMessage) {
        return twoFAMessage;
      }
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    })
    .finally(() => GlobalStore.endLoading());
};

export const getIsLogged = async (type?: "adminRedirect") => {
  GlobalStore.startLoading();
  return axios
    .get("/user/me")
    .then(({ data }) => {
      if (data?.emailVerified === false) {
        if (type === "adminRedirect") {
          GlobalStore.setError("User email is not verified");
          localStorage.removeItem("isLoggedAsAdmin");
        }
        return null;
      }
      if (type === "adminRedirect") {
        localStorage.setItem("loggedAsAdmin", "true");
      }
      UserStore.setAuthenticated(data);
      return true;
    })
    .catch(() => {
      if (type === "adminRedirect") {
        localStorage.removeItem("isLoggedAsAdmin");
        GlobalStore.setError("Incorrect admin token");
      }
      return null;
    })
    .finally(() => GlobalStore.endLoading());
};

export const checkIsLogged = async (type?: "adminRedirect") => {
  GlobalStore.startLoading();

  const userToken = localStorage.getItem("access");
  axios.defaults.headers.common.Authorization = `Bearer ${userToken}`;

  return axios
    .get("/user/me")
    .then(({ data }) => {
      if (data?.emailVerified === false) {
        if (type === "adminRedirect") {
          GlobalStore.setError("User email is not verified");
          localStorage.removeItem("isLoggedAsAdmin");
        }
        return null;
      }
      if (type === "adminRedirect") {
        localStorage.setItem("loggedAsAdmin", "true");
      }
      UserStore.setStatistics(null);
      UserStore.setRequestedForms(null);
      CardsStore.clearCardsStore();
      StakesStore.setActiveStakes(null);
      WalletStore.setWalletsList(null);
      UserStore.setAuthenticated(data);
      return true;
    })
    .catch(() => {
      if (type === "adminRedirect") {
        localStorage.removeItem("isLoggedAsAdmin");
        GlobalStore.setError("Incorrect admin token");
      }
      UserStore.logOut();
      return null;
    })
    .finally(() => {
      GlobalStore.endLoading();
      localStorage.removeItem("companyAccess");
    });
};

export const initiateVerifyEmail = async (
  credentials: VerifyEmailCredentials
) => axios.post("/user/verify/email", credentials);

export const resetPassword = async (credentials: VerifyEmailCredentials) =>
  axios.post("/user/password/reset", credentials);

export const getIdentifyUser = async () => axios.get("/user/verify/identity");

export const changePasswordAfterReset = async ({
  resetCode,
  password,
}: ChangePasswordCredentials) =>
  axios.post(`/user/password/reset/${resetCode}`, { password });

export const verifyEmail = async ({ verifyCode }: CondirmEmailCredentials) =>
  axios.post(`/user/verify/email/${verifyCode}`);

export const updateJuniorPersonalData = async (data: JuniorPersonalData) =>
  axios.post(`/user/personal-data`, data);

export const getRefferals = async () =>
  axios.get<ReferralResponse>("/user/referrals");

export const getFeesAndLimits = async () =>
  axios
    .get("/user/fees-and-limits")
    .then(({ data }) => {
      UserStore.setFeesAndLimits(data);
    })
    .catch(() => null);

export const getUserStatistics = async () =>
  axios
    .get("/user/statistics")
    .then(({ data }) => {
      UserStore.setStatistics(data);
    })
    .catch(() => null);

export const getUserPersonalData = async () =>
  axios.get<JuniorPersonalData>("/user/personal-data");

export const add2FAVerification = async () =>
  axios.post<Add2FaVerificationResponse>("/user/2fa/add", {
    method: "AUTHENTICATOR",
  });

export const toggle2FAVerification = async (data: Toggle2FaVerificationData) =>
  axios.put("/user/2fa/toggle", data);

export const changePassword = async (data: ChangePasswordReq) =>
  axios.put("/user/change-password", data);

export const telegramLogIn = async (telegramUserData: string) => {
  GlobalStore.startLoading();
  // Convert the data object to a flat structure
  // const flatData = flattenObject(telegramUserData);

  // const params = new URLSearchParams(flatData);

  return axios
    .post<UserInterface>(`/user/telegram-login?${telegramUserData}`)
    .then(({ data }) => {
      token.set(data.token);
      UserStore.setAuthenticated(data);
      UserStore.setIsLoggedFromTelegram();
      localStorage.setItem("loggedFromTelegram", "true");
      if (window.Telegram?.WebApp) {
        const webApp = window.Telegram.WebApp;
        webApp.expand();
      }
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        GlobalStore.setError(err.response.data.message);
      }
    })
    .finally(() => GlobalStore.endLoading());
};
