const ru = {
  BACK: "Назад",
  BALANCE: "Баланс",
  GO_BACK: "Вернуться назад",
  CLOSE: "Закрыть",
  COPY: "Копировать",
  COPIED: "Скопировано!",
  OTHERS: "Другие",
  ALL: "Все",
  TOTAL: "Итого",
  CONFIRM: "Подтвердить",
  CONFIRMED: "подтверждено",
  PURCHASE: "Покупка",
  SALE: "Продажа",
  TRADE: "Обмен",
  WARNING: "Внимание",
  SEARCH: "Поиск",
  NO_DATA: "Нет данных",
  PAGE: "Страница",
  OF: "из",
  TO: "на",
  SHOW_ALL: "Показать все",
  PREVIOUS: "Предыдущая",
  NEXT: "Следующая",
  FROM: "от",

  BUY_CRYPTO: "Купить крипту",
  SELL_CRYPTO: "Продать крипту",
  BUY_SELL_CRYPTO: "Купить/Продать крипту",
  PROFILE_MENU: "Меню профиля",
  METAMASK_MENU: "Меню MetaMask",

  PROFILE_SETTINGS: "Настройки профиля",
  VERIFICATION: "Верификация",
  ACTIVITY_LOG: "Журнал активности",
  REFERRALS: "Рефералы",
  LOG_OUT: "Выход",
  DISCONNECT_METAMASK_ACCOUNT: "Отключить учетную запись MetaMask",
  ADD_ANOTHER_WALLET: "Добавить другой кошелек",

  TRADE_REVIEW: "Обзор сделки",
  RATE: "Курс",
  YOU_SPEND: "Вы тратите",
  YOU_RECEIVE: "Вы получаете",
  FEES: "Сборы",
  DONT_SHOW_AGAIN: "Больше не показывать это подтверждение",
  WITHOUT_CONFIMATION: "Продолжить без подтверждения",
  CLOSE_MODAL: "закрыть модальное окно",
  CLOSE_SELECT: "закрыть выбор",
  TO_SPEND: "Потратить",
  TO_RECEIVE: "Получить",
  ERROR_NO_AMOUNT: "Сумма превышает ваш баланс",
  ERROR_NO_AMOUNT_STAKE: "Сумма превышает ваш стейкинг",
  SELECT_ASSET: "Выбрать актив",

  DASHBOARD: "Панель управления",
  STAKING: "Стейкинг",
  WALLET: "Кошелек",
  HISTORY: "История",
  FEATURES: "Особенности",
  FUTURES: "Фьючерсы",
  PROFILE: "Профиль",

  ASSETS: "Активы",
  AMOUNT: "Сумма",
  PRICE: "Цена",
  VALUE: "Значение",
  D7_GRAPH: "График 7d",
  H24: "24h",

  ID: "ID",
  DATE: "Дата",
  TYPE: "Тип",
  CURRENCY: "Валюта",
  FEE: "Сбор",

  ACCOUNT_NAME: "Пользователь",
  COIN: "Криптомонета",

  ACTIVITY: "Активность",
  DEVICE: "Устройство",
  BROWSER: "Браузер",
  PLACE: "Место",

  TOTAL_BALANCE: "Общий баланс",
  FEE_SCHEDULE: "Тарифная сетка",
  TRADING_VOLUME_30: "Торговый объем за 30 дней",
  TRADE_BALANCES: "Торговые остатки",
  POSITION_VALUATION: "Оценка позиции",

  CURRENT_FEE: "Текущая комиссия",
  NEXT_FEE: "Следующая комиссия",
  MARGIN_TRADE_BALANCE: "Маржинальный торговый баланс",
  MARGIN_EQUITY: "Маржинальный капитал",
  USED_MARGIN: "Использованная маржа",
  FREE_MARGIN: "Свободная маржа",
  MARGIN_LEVEL: "Уровень маржи",
  OPENING_COST: "Стоимость на начало периода",
  CURRENT_VALUATION: "Текущая оценка",
  PROFIT_LOSS: "Прибыль / Убыток",

  LADGER: "Сводка",
  ALL_CURRENCIES: "Все валюты",
  ALL_TRANSACTIONS: "Все транзакции",
  START_DATE_TIME: "с Дата/время",
  END_DATE_TIME: "до Дата/время",
  FILTER: "Фильтр",
  TRANSFER: "Перевод",
  WITHDRAWAL: "Вывод",
  DEPOSIT: "Депозит",

  LOGIN: "Логин",
  NEW_IP_REGISTERED: "Зарегистрирован новый IP",
  SIGN_UP: "Регистрация",

  MARKET: "Рынок",
  LIMIT: "Лимит",
  DONE: "Завершить",

  ACCOUNT_VERIFICATION: "Верификация аккаунта",
  SCAN_QR_PHONE: "Сканирование QR-кода с помощью камеры телефона",
  ALTERNATIVELY: "Альтернативно",
  CLICK_TO_CONTINUE:
    "Нажмите здесь, чтобы продолжить, используя текущее устройство",
  REFERRAL_LIST: "Приглашенные пользователи",
  YOUR_REFERRAL: "Ваша реферальная ссылка",
  COPY_LINK: "Копировать ссылку",

  EMAIL_ADDRESS: "Адрес электронной почты",
  CONFIRMED_UP: "Подтверждено",
  VERIFICATION_STATUS: "Статус верификации",
  UNVERIFIED: "Не верифицирован",
  PROVIDE_INFO:
    "Прежде чем вы сможете вносить и снимать средства, пожалуйста, предоставьте следующую информацию и подтверждающие документы для верификации Вашего аккаунта.",
  VERIFY: "Верифицировать",
  DEFAULT_CURRENCY: "Валюта по умолчанию",
  SECURITY_SETTINGS: "Настройки безопасности",
  PASSWORD: "Пароль",
  CHANGE: "Изменить",
  TWO_FACT_AUTH: "Двухфакторная аутентификация",
  DISABLED: "Отключено",
  ENABLED: "Включено",
  TWO_FACT_RECOMMEND:
    "Мы настоятельно рекомендуем защитить вашу учетную запись с помощью двухфакторной аутентификации (2FA)",
  ACTIVITY_TYPE: "Тип активности",
  VERIFY_IDENTITY:
    "Верифицируйте свой аккаунт, чтобы получить доступ к дополнительным функциям и более высоким лимитам",
  IN_PROGRESS: "В процессе",
  FINISHED: "Завершено",
  PROVIDE_PERSONAL_INFO: "Предоставьте личную информацию",
  MINUTES: "минуты",
  REQUIRES: "Требуется",
  PROVIDE: "Предоставьте",
  PROVIDE_FINANCIAL_INFO: "Предоставьте финансовую информацию",
  A_FEW_DAYS: "Несколько дней",

  REQUIREMENTS: "Требования",
  COLLAPSE_REQUIREMENTS: "Свернуть все требования",
  EXPAND_REQUIREMEN: "Развернуть все требования",

  CRYPTO_DEPOSIT: "Депозиты в криптовалюте",
  CRYPTO_OPERATIONS: "Покупка / продажа / конвертация криптовалюты",
  CRYPTO_WITHDRAWALS: "Вывод криптовалюты",
  BANK_TRANSFERS: "Банковские переводы",
  TRADE_WITH_MARGIN: "Маржинальная торговля",
  STACKING_REWARDS: "Стейкинг",
  CRYPTO_TRADE: "Торговля криптофьючерсами",
  UNLIMITED: "Неограниченно",
  DAY_K: "$5K/день",
  DAY_HIGH_K: "$1K/день",
  DAY_MONTH_K: "$100K/день, $500K/месяц",

  AMOUNT_TO_STAKE: "Сумма стейкинга",
  AMOUNT_TO_UNSTAKE: "Сумма отмены стейкинга",
  YEARLY_REWARD: "Ежегодное вознаграждение",
  APPROXIMATELY: "Приблизительно",
  REWARDS: "Вознаграждения",
  WEEKLY: "Еженедельно",
  UNSTACKING_FEE: "Комиссия за отмену стейкига",
  UNSTAKE_CONFIRMATION: "Подтверждение отмены стейкинга",
  UNSTAKE_CONFIRM: "Вы уверены, что хотите отменить стейкинг",
  UNSTAKE_INITIATED: "Начата отмена стейкинга",

  CHOOSE_METHOD_ASSET: "Выберите актив или способ оплаты",
  CHECK_DEPOSIT_ADDRESS_1: "Пожалуйста, перепроверьте",
  CHECK_DEPOSIT_ADDRESS_2: "адрес депозита!",
  SENDING_FUNDS_INFO:
    "Отправка средств на неправильный адрес может привести к безвозвратной потере вашей криптовалюты.",
  DEPOSIT_ADDRESS: "Адрес депозита",
  CONFIRM_PROCEED_INFO: "Я понимаю, что процедура может занять до 48 часов",
  COPY_DEPOSIT_ADDRESS: "Скопировать адрес депозита",
  SENDING_FUNDS_CASH_INFO: "Пожалуйста, перепроверьте адрес евро депозита!",
  SENDING_CASH_FUNDS_INFO:
    "Отправка средств на неправильный адрес может привести к безвозвратной потере ваших денег.",

  WITHDRAW: "Снять деньги",
  CHOOSE_METHOD: "Выберите способ оплаты",
  WITHDRAW_WARN_1: "Вывод только",
  WITHDRAW_WARN_2: "адреса",
  WITHDRAW_WARN_3:
    "Вывод средств на адреса, сгенерированные для других сетей, может привести к потере средств.",
  WITHDRAW_REVIEW: "Проверка снятия",

  TOTAL_REWARDS: "Общее вознаграждение",
  STAKING_BALANCE: "Стейкинг баланс",
  STAKING_ASSETS: "Стейкинг активы",
  STAKING_OPTIONS: "Варианты стейкинга",
  REWARD_PERCENT: "Вознаграждение %",
  TRANSACTIONS: "Транзакции",
  ASSET: "Актив",
  COST: "Стоимость",
  STATUS: "Статус",
  REWARD: "Вознаграждение",

  IN_ORDERS: "В заказах",
  FREE: "Свободны",
  MARGIN_PORTFOLIO: "Стоимость маржинального портфеля (USD)",
  USED_FREE_MARGIN: "Использованная/свободная маржа",
  UNREALIZED_P_L: "Нереализованный P/L",
  FUNDING_LIMITS: "Лимиты финансирования",
  WITHDRAWAL_LIMITS: "Лимиты на снятие средств",
  CASH: "Наличные",
  CRYPTO: "Криптовалюта",
  DAILY_DEPOSITS: "Ежедневные депозиты",
  DAILY_WITHDRAWALS: "Ежедневные снятие средств",
  MONTHLY_DEPOSITS: "Ежемесячные депозиты",
  MONTHLY_WITHDRAWALS: "Ежемесячные снятие средств",
  YEARLY_DEPOSITS: "Ежегодные депозиты",
  YEARLY_WITHDRAWALS: "Ежегодные снятия средств",
  DAILY: "Ежедневно",
  MONTHLY: "Ежемесячно",
  YEARLY: "Ежегодно",
  RECENT_TRANSACTIONS: "Последние транзакции",
  CRYPTO_BALANCES: "Криптобаланс",
  CASH_BALANCES: "Валютный баланс",

  NEW_ORDER: "Новый заказ",
  ORDERS: "Заказы",
  POSITIONS: "Позиции",
  TRADES: "Сделки",
  PAIR: "Пара",
  EXECUTED: "Исполнена",
  VOLUME: "Объем",
  ORDER_TYPE: "Тип заказа",
  CURRENCY_PAIR: "Валютная пара",
  LEVERAGE: "Кредитное плечо",
  SCHEDULED_START: "Запланированное начало",
  SCHEDULED_EXPIRE: "Запланированное истечение",
  REASON: "Причина",
  VOLUME_EXECUTED: "Объем выполнен",
  AVERAGE_PRICE: "Средняя цена",
  OPENED: "Открыт",
  CLOSED: "Закрыто",
  MAKER_FEE: "Сбор за создание",
  TAKER_FEE: "Сбор за прием",
  TOTAL_FEE: "Общий сбор",
  OPEN: "Открыто",
  TERMS: "Условия",
  ORDER: "Заказ",
  TO_ORDER: "Заказать",
  SELL: "Продажа",
  FOR: "за",
  REQUESTED_DOCUMENTS: "Запрашиваемые документы",
  DOCUMENTS: "Документы",
  FILL_FORM: "Заполнить форму",

  ACCOUNT_LOGIN: "Логин учетной записи",
  EMAIL: "Email",
  ENTER_EMAIL: "Введите свой email",
  ENTER_PASSWORD: "Введите пароль",
  NEW_PASSWORD: "Новый пароль",
  CURRENT_PASSWORD: "Текущий пароль",
  ENTER_CURRENT_PASSWORD: "Введите текущий пароль",
  RESET_PASSWORD: "Сбросить пароль",
  FORGOT_PASSWORD: "Забыли пароль",
  REFERRAL_CODE: "Реферальный код",
  OR: "Или",
  CONTINUE_WITH: "Продолжить",
  NO_ACCOUNT: "У вас нет аккаунта?",
  HAVE_ACCOUNT: "Уже есть аккаунт?",
  CREATE_ACCOUNT: "Создать учетную запись",
  LOG_IN: "Войти",
  AGREE_WITH: 'Нажимая на кнопку "Зарегистрироваться", вы соглашаетесь с',
  TERMS_AND_CONDITIONS: "Условия и положения",
  CONTINUE: "Продолжить",
  PASSWORD_RECOVERY: "Восстановление пароля",
  LEARN_MORE: "Узнать больше",
  EMAIL_NOT_RECEIVE: "Не получили письмо?",
  RESEND_IN: "Отправить повторно",
  RESEND_EMAIL: "Повторно отправить email",
  CREATE_NEW_PASSWORD: "Создать новый пароль",
  ENTER_NEW_PASSWORD: "Ввести новый пароль",
  CONFIRM_PASSWORD: "Подтвердить пароль",
  CREATE: "Создать",
  PASSWORD_SUCCESS_CHANGE: "Пароль был успешно изменен",
  SECURITY_CHANGE_WARNING:
    "В целях защиты вашей учетной записи, снятие средств будет отключено на 24 часа после внесения изменений",
  RESET_LINK_SENT:
    "Электронное письмо со ссылкой для восстановления пароля было отправлено на",
  CLICK_TO_RESEND: "Нажмите кнопку ниже, чтобы отправить письмо повторно.",
  EMAIL_CONFIRMED:
    "Электронная почта подтверждена - добро пожаловать в FNT Crypto!",
  EMAIL_CONFIRMED_TEXT:
    "Отличные новости! Ваша электронная почта была подтверждена, и теперь ваш аккаунт в FNT Crypto активен. Приготовьтесь погрузиться в наше сообщество",
  DEAR: "Уважаемый",
  EXPLORE_FNT: "Исследуйте FNT Crypto",
  SEND_VERIFY_TO_EMAIL: "Отправить верификацию на электронную почту",
  NO_WALLETS_YET: "Пока нет кошельков",
  ADD_WALLET: "Добавить кошелек",
  SELECT_NETWORK: "Выбрать сеть",
  VERIFY_EMAIL: "Пожалуйста, проверьте свою электронную почту",
  EMAIL_SENT: "Вы почти у цели! Мы отправили письмо на",
  EMAIL_SENT_INFO:
    "Просто нажмите на ссылку в письме, которое отправлено на вашу электронную почту, чтобы завершить регистрацию. Если вы не видите ее, возможно, вам нужно проверить папку со спамом.",
  CANT_FIND_EMAIL: "Все еще не можете найти письмо?",
  NEED_HELP: "Нужна помощь?",
  CONTACT_US: "Свяжитесь с нами",
  SOMETHING_GOES_WRONG: "Что-то пошло не так...",
  EMAIL_NOT_CONFIRMED_TEXT:
    "Пожалуйста, попробуйте позже или свяжитесь со службой поддержки в случае, если электронная почта все еще не подтверждена",
  PROCEED_INFO: "Процедура может занять до 48 часов",
  LIMIT_ERROR:
    "Лимитная цена не может быть больше или меньше 10% от рыночной цены",
  SEND: "Отправить",
  AVAILABLE: "Доступен",
  NOT_AVAILABLE: "Не доступен",
  LIMIT_PRICE: "Лимитная цена",
  EXCHANGE: "Обмен",
  BUY_PRODUCT: "Купить продукт",
  COMMISSION: "Сбор",
  SPEND: "Потратить",
  LIMIT_ORDER_PRICE: "Цена лимитного ордера",
  OPEN_ORDERS: "Открытые ордера",
  ORDERS_HISTORY: "История ордеров",
  CANCELED: "Отменено",
  EXPIRED: "Истекший",
  PROGRESS: "Прогресс",
  ACTIVE: "Активный",
  CANCELLED: "Отменено",
  NO_ACTIVE_STAKES: "Активные стейки не найдены",
  MARKET_PURCHASE_CONFIRMED: "Покупка по рыночной цене подтверждена",
  MARKET_SALE_CONFIRMED: "Продажа по рыночной цене подтверждена",
  LIMIT_ORDER_CONFIRMED: "Лимитный ордер размещён",
  ACCURAL_PERIOD: "Период начисления",
  GAMES: "Игры",
  CHOOSE_GAMES: "Выберите игры",
  CARDS: "Карты",
  ENABLE: "Включить",
  DISABLE: "Отключить",
  BALANCE_BEFORE_TRANSACTION: "Баланс до транзакции",
  CURRENTLY_UNAVAILABLE_PRODUCT:
    "Извините, но товар в настоящее время недоступен",
  HIDE_EMPTY_WALLETS: "Скрыть пустые кошельки",
  RESUBMIT: "Отправить повторно",
  NO_WALLETS_FOUND: "Кошельки не найдены",
  EARNING: "Заработок",

  EMAIL_INVALID: "Неверный адрес электронной почты",
  EMAIL_REQUIRED: "Необходим адрес электронной почты",
  PASSWORD_INVALID: "Неверный пароль",
  PASSWORD_REQUIRED: "Необходим пароль",
  PASSWORD_DIFFERENT: "Пароли не совпадают",
  ACCEPT_TERMS_REQUIRED: "Требуется принять Условия использования",
  FIAT: "Фиат",
  CONTACT_SUPPORT: "Обратиться в поддержку",
  MERCHANT: "Торговец",
  CARD_AMOUNT: "Сумма карты",
  TRANSACTION_AMOUNT: "Сумма транзакции",
  CURRENTLY_UNAVAILABLE_CURRENCY:
    "Извините, но эта валюта в настоящее время недоступна для транзакции",
  BALANCES: "Балансы",
  WITHDRAW_CARD: "Вывод с карты",
  TOP_UP_CARD: "Пополнение карты",
  ORDER_CARD: "Заказ карты",
  LAST_EXCHANGE_TRANSACTIONS: "Последние транзакции обмена",
  MINIMUM_DEPOSIT: "Мин. депозит",
  MAXIMUM_DEPOSIT: "Макс. депозит",
  BANK_TRANSFER_REFUND: "Возврат денег",
  BANK_TRANSFER_DEPOSIT: "Депозит банковским переводом",
  BANK_TRANSFER_WITHDRAW: "Вывод банковским переводом",
  EXCHANGE_PROVIDER_COMMISSION: "Комиссия провайдера обмена",
  OVERPAYMENT: "Переплата",
  BET: "Ставка",
  TOURNAMENT_WINNING: "Победа в турнире",
  DETAILS: "Подробности",
  ACCEPT: "Принять",
  COOKIES_DESC:
    "Этот веб-сайт использует файлы cookie, чтобы обеспечить вам максимально эффективное использование нашего веб-сайта.",
  DELIVERY_ADDRESS: "Адрес доставки",
  ADD_DELIVERY_ADDRESS: "Добавить адрес доставки",
  ADD_NEW_DELIVERY_ADDRESS: "Добавить новый адрес доставки",
  DELIVERY_METHOD: "Способ доставки",
  STANDARD_MAIL: "Стандартная почта",
  COURIER: "Курьер",
  ADDRESS: "Адрес",
  ENTER_ADDRESS: "Введите адрес",
  MANAGE_CARD: "Управление картой",
  CRYPTO_CARDS: "Криптокарты",
  GIFT_CARDS: "Подарочные карты",
  MY_CRYPTO_CARDS: "Мои криптокарты",
  BUY_NEW_CARD: "Купить новую карту",
  LAST_TRANSACTIONS: "Последние транзакции",
  COMING_SOON: "Скоро",
  TOTAL_PRICE: "Общая цена",
  DELIVERY_RESTRICTIONS:
    "На данный момент доставка доступна только в страны ЕЭЗ, Великобританию и Швейцарию (Ваши адреса из других стран отображаться не будут)",
  OVERVIEW: "Обзор",
  CARD_COST: "Стоимость карты",
  PLASTIC_COST: "Стоимость пластика",
  DELIVERY_COST: "Стоимость доставки",
  ORDER_PLASTIC_CARD: "Заказ пластиковой карты",
  TWO_FA_FOR_DETAILS: "Вам необходимо включить 2FA, чтобы увидеть данные карты",
  TWO_FA_FOR_PIN: "Вам необходимо включить 2fa, чтобы увидеть пин-код",
  CARD_DETAILS: "Детали карты",
  VIEW_DETAILS: "Просмотреть детали",
  PURCHASE_PLASTIC: "Купить пластик",
  VIEW_PIN: "Просмотр PIN-кода",
  CARD_TOP_UP_LIMITS: "Лимиты пополнения карты",
  CARD_TOP_UP_LIMITS_UPGRADE:
    "Это лимиты карты, которые вы можете увеличить, повысив уровень верификации.",
  DAILY_TOP_UPS: "Ежедневные пополнения",
  MONTHLY_TOP_UPS: "Ежемесячные пополнения",
  YEARLY_TOP_UPS: "Ежегодные пополнения",
  INCREASE_LIMITS: "Увеличить лимиты",
  TOTAL_TO_SPEND: "Итого потратить",
  TOTAL_TO_RECEIVE: "Итого к получению",
  AMOUNT_TO_RECEIVE: "Сумма к получению",
  AMOUNT_TO_RECEIVE_BEFORE_FEE: "Сумма к получению до уплаты комиссии",
  AMOUNT_TO_SPEND: "Сумма к потратить",
  AMOUNT_TO_SPEND_BEFORE_FEE: "Сумма к потратить до уплаты комиссии",
  TOP_UP: "Пополнить",
  ENTER_AMOUNT: "Введите сумму",
  SELECT_AMOUNT: "Выберите сумму",
  HOW_TO_USE: "Как использовать",
  CRYPTO_CARD_ACTIVATE:
    "Карта будет активирована после завершения процесса покупки",
  LIMITS: "Лимиты",
  KYC_VERIFICATION_INFO_REQUIERED:
    "Требуется верификация учетной записи и дополнительная личная информация",
  KYC_WARNING_COUNTRY:
    "Внимание! Криптокарты доступны только для жителей стран ЕЭЗ, Великобритании и Швейцарии.",
  KYC_VERIFICATION_REQUIRED:
    "- Покупка криптокарт требует верификации аккаунта на уровне Middle или выше, и не волнуйтесь, это займет всего около 4-5 минут.",
  KYC_VERIFICATION_DOCS_REQUIERED:
    "- После проверки учетной записи на Middle уровне или выше также требуется дополнительная личная информация пользователя и подтверждение адреса. (Проверка и подтверждение информации может длиться день или более)",
  KYC_VERIFICATION_APPROVE_IN_PROCESS:
    "В настоящее время ваши документы поданы и находятся на рассмотрении... Это может занять некоторое время",
  KYC_VERIFICATION_ACCESS_REQUEST:
    "- На последнем этапе вам нужно будет запросить доступ. (Одобрение доступа может длиться неделю или больше)",
  KYC_VERIFICATION_DOCS_APPROVED:
    "Ваша дополнительная личная информация пользователя и подтверждение адреса одобрены! Теперь вы можете запросить доступ для использования этой услуги!",
  KYC_VERIFICATION_ERROR:
    "Ваш запрос на доступ был отклонен. Пожалуйста, свяжитесь с",
  KYC_REQUEST_PENDING:
    "Ожидается одобрение доступа. Вам нужно подождать. (Это может продлиться неделю или больше)",
  FILL_FORMS: "Заполнить формы",
  VERIFY_ACCOUNT: "Верифицировать аккаунт",
  REQUEST_ACCESS: "Запросить доступ",
  KYC_VERIFICATION_SUPPORT: "поддержкой.",
  FORM_VERIFICATION_ERROR:
    "Ваши документы были отклонены. Пожалуйста, свяжитесь с нашей",
  FORM_VERIFICATION_SUPPORT: "поддержкой",
  FORM_VERIFICATION_ERROR_END: ", чтобы ознакомится с деталями",
  UPLOAD: "Загрузить",
  CASHBACK: "Кэшбэк",
  CARD_TRANSFER_CASHBACK: "Кэшбэк",
  REJECT_REASON: "Причина отклонения:",
  PREVIOUS_REJECT_REASON: "Предыдущая причина отклонения:",
  PAY_METHOD: "Способ оплаты",
  DIVIDEND: "Дивиденды",
  PAY_WITH: "Оплатить с помощью",
  INCLUDING_FEES: "Включая комиссию",
  NETWORK_FEE: "Комиссия сети",
  SERVICE_FEE: "Комиссия за обслуживание",
  MINIMUM_AMOUNT_IS: "Минимальная сумма",
  AMOUNT_MUST_BE_LESS_THAN: "Сумма должна быть меньше",
  ERROR_CREATING_ADDRESS: "Ошибка при создании нового адреса кошелька",
  ERROR_CREATING_ADDRESS_CRYPTO: "(необходим для покупки криптовалюты)",
  TRY_AGAIN: "Попробывать еще раз",
  ACTIVATE: "Активировать",
  CARD_ACTIVATION: "Активация карты",
  ACTIVATE_PLASTIC: "Активировать пластик",
  ENTER_CODE_TO_ACTIVATE_CARD: "Введите код для активации карты",
  ENTER_CODE: "Введите код",
  SUCCESS_CARD_ACTIVE: "Активация карты прошла успешно",
  UP_TO: "До",
  YOU_SPENT: "Вы потратили",
  LOGGED_FROM_ADMIN_PANEL:
    "Вы вошли в учетную запись пользователя из панели администратора",
  NEW_WALLET_ADDRESS_CREATING:
    "Создается новый адрес кошелька. Пожалуйста, подождите...",
  TOP_UP_FEES: "Комиссия за пополнение",
  WITHDRAW_FEES: "Комиссия за снятие средств",
  TRANSACTION_FEES: "Комиссия за транзакцию",
  UNKNOWN_PAYBIS_ERROR:
    "Неизвестная ошибка при загрузке виджета Paybis. Чаще всего он недоступен по юридическим причинам",
  CARD_LIMITS: "Лимиты карты",
  MONTHLY_TRANSACTIONS: "Ежемесячные транзакции",
  CODE: "Код",
  FACE_VALUE: "Номинальная стоимость",
  CARD_INFO: "Информация о карте",
  DRAG_AND_DROP_INFO: "Перетащите файл сюда или щелкните, чтобы выбрать файл",
  ASSET_OR_PAYMENT_METHOD: "Актив или способ оплаты",
  CRYPTO_CARD_REVIEW: "Обзор криптокарты",
  GIFT_CARD_REVIEW: "Обзор подарочной карты",
  CHANGE_PASSWORD: "Изменить пароль",
  PASSWORD_CHANGED_SUCCESSFULLY: "Пароль успешно изменен",
  TRANSACTION_REVIEW: "Обзор транзакции",
  WITHDRAW_WAS_SUCCESSFUL: "Вывод прошел успешно",
  TOP_UP_WAS_SUCCESSFUL: "Пополнение прошло успешно",
  NOW_YOUR_BALANCE: "Теперь баланс вашей карты составляет",
  YOU_RECEIVED: "Вы получили",
  DEPOSIT_VERIFY_REQUIRED:
    "Для депозита требуется верификация счета на уровне SENIOR",
  BANK: "Банк",
  BANK_ADDRESS: "Адрес банка",
  DEPOSIT_WARNING:
    "ВНИМАНИЕ! ОБЯЗАТЕЛЬНО укажите этот «код» в деталях транзакции! В противном случае транзакция не будет выполнена!",
  PURCHASE_PLASTIC_CARD: "Приобретение пластиковой карты",
  PLASTIC_CARD_SUCCESS_PURCHASE: "Пластиковая карта успешно заказана",
  START_EARN_AGAIN_1:
    "Вы можете снова начать зарабатывать вознаграждения на свой",
  START_EARN_AGAIN_2: "в любое время.",
  WITHDRAW_VERIFY_REQUIRED:
    "Вывод требует верификации аккаунта на уровне MIDDLE или выше",
  WITHDRAW_FIAT_VERIFY_REQUIRED:
    "Для вывода требуется верификация аккаунта на уровне SENIOR.",
  SELL_CRYPTO_VERIFY_REQUIRED:
    "Для продажи криптовалюты требуется верификация аккаунта на уровне MIDDLE.",
  BUY_CRYPTO_VERIFY_REQUIRED:
    "Для покупки криптовалюты требуется верификация аккаунта на уровне MIDDLE.",
  ENTER_NAME: "Введите имя",
  ENTER: "Введите",
  ENTER_DETAILS: "Введите детали",
  PROCEDURE_WARN:
    "Я понимаю, что выполнение процедуры может занять до 48 часов",
  TWO_FA_FOR_WITHDRAW: "Вам необходимо включить 2fa для использования Вывода",
  TWO_FA_FOR_PIN_CODE: "Введите код 2FA, чтобы увидеть PIN-код",
  CARD_BALANCE: "Баланс карты",
  TWO_FA_FOR_CARD_DETAILS: "Введите код 2FA, чтобы увидеть данные карты",
  CARD_NUMBER: "НОМЕР КАРТЫ",
  EXPIRY_DATE: "ДАТА ИСТЕЧЕНИЯ СРОКА",
  SECURITY_CODE: "КОД БЕЗОПАСНОСТИ",
  CARD_HOLDER: "Держатель карты",
  CARD_PIN_CODE: "PIN-код карты",
  KEEP_PIN_CODE_SAFE:
    "Сохраняйте свой PIN-код в тайне, никогда и никому его не сообщайте",
  TRANSACTION_EXCEEDS_LIMITS_DAILY: "Транзакция превышает ваши дневные лимиты",
  TRANSACTION_EXCEEDS_LIMITS_MONTHLY:
    "Транзакция превышает ваши месячные лимиты",
  TRANSACTION_EXCEEDS_LIMITS_YEARLY: "Транзакция превышает ваши годовые лимиты",
  OUT_OF_LIMITS: "Превышение лимитов",
  INCREASE_LIMITS_TEXT:
    "Чтобы увеличить лимиты, вы можете пройти верификацию на более высокий уровень",
  SENIOR_VERIFICATION: "Senior верификация",
  SENIOR_VERIFICATION_TEXT_1:
    "Чтобы пройти верификацию Senior и получить доступ к депозитам и выводам фиатной валюты, воспользуйтесь ссылкой ниже, чтобы запланировать видеозвонок с одним из наших специалистов.",
  SENIOR_VERIFICATION_TEXT_2:
    "Убедитесь, что ваш паспорт или другой действительный документ готов к считыванию кода MRZ и распознаванию лиц. Если вы проживаете в стране, отличной от страны вашего гражданства, пожалуйста, также предоставьте вид на жительство в этой стране во время видеозвонка.",
  SENIOR_VERIFICATION_TEXT_3:
    "Предоставление ложной информации заранее будет передано в соответствующие органы, банки и поставщикам услуг KYC/AML, что приведет к появлению специальных отметок в вашем профиле.",
  SENIOR_VERIFICATION_SCHEDULE:
    "Чтобы запланировать видеозвонок в удобное для вас время, воспользуйтесь следующей ссылкой:",
  SENIOR_VERIFICATION_LINK_1:
    "После того, как вы забронировали временной интервал, воспользуйтесь ссылкой",
  SENIOR_VERIFICATION_LINK_2: "для доступа к видеозвонку.",
  SENIOR_VERIFICATION_ADVISE:
    "Мы рекомендуем заранее подготовиться к видеозвонку и обеспечить оптимальное качество видео для процесса проверки.",
  SENIOR_VERIFICATION_QUESTIONS_1:
    "Если у вас есть вопросы или вы столкнулись с какими-либо проблемами, не стесняйтесь обращаться в нашу",
  SENIOR_VERIFICATION_QUESTIONS_2: "службу поддержки.",
  SCAN_QR_BY_AUTH_APP:
    "Просканируйте QR-код с помощью приложения аутентификации",
  MANUALY_ENTER_TO_AUTH_APP:
    "Или вручную введите этот секрет в приложение аутентификации",
  ENTER_CODE_FROM_AUTH_APP: "Введите код из приложения аутентификации ниже",
  ACCOUNT_VERIFICATION_REQUIRED: "Требуется верификация учетной записи",
  NO_CARDS_FOUND: "Карты не найдены",
  CHOOSE_CARDS_DESIGN: "Выберите дизайн карты",
  NO_CARDS_ACTIVE_YET: "У вас пока нет активных криптокарт",
  NO_GIFT_CARDS_ACTIVE_YET: "У вас пока нет активных подарочных карт",
  SUBMIT: "Отправить",
  ERROR_LOADING_GAME: "Ошибка при загрузке игры",
  REFRESH: "Обновить",
  PURCHASE_CARD_RESTRICTION:
    "Для покупки карты требуется верификация учетной записи на уровне Junior или выше, и не волнуйтесь, это займет всего около 2 минут.",
  BUY_CARD: "Купить карту",
  BUY_SAME_CARD: "Купить такую же карту",
  CARD_TITLE: "Название карты",
  SAVE: "Сохранить",
  EDIT: "Изменить",
  CARD_NOTES: "Заметки к карте",
  NO_PENDING_DOCS: "Нет ожидающих ответа запросов документов",
  NOTE: "Обратите внимание",
  SELL_CRYPTO_NOTE:
    "Продажа не будет автоматической, вам будет необходимо сделать трансфер на кошелек, предоставленный провайдером в конце",
  BUY: "Купить",
  REQUIRED_FIELD: "Обязательное поле",
  INVALID_PHONE_NUMBER: "Неверный номер телефона",
  CONNECTED: "Подключен",
  CONNECT_ACCOUNT: "Подключить учетную запись",
  MAIN: "Основной",
  CONNECTED_ACCOUNT: "Подключенный аккаунт",
  AMOUNT_TO_DEPOSIT: "Сумма для внесения",
  ERROR_METAMASK_CHAIN:
    "Sorry, but we support only ETH MetaMask deposits. Please change your connected account on ETH.",
  INTERNAL_TRANSFER: "Внутренний перевод",
  RECIPIENTS_EMAIL: "Электронная почта получателя",
  TRANSFER_NOTE: "Примечание",
  TRANSFER_REVIEW: "Проверка перевода",
  SPENT: "Потрачено",
  RECEIVED: "Получено",
  INITIATED: "Инициирован",
  NO_CRYPTO_WALLETS_FOUND:
    "Кошельки не найдены. Вам нужно создать хотя бы один, чтобы использовать эту услугу.",
  GO_TO_MAIN_PAGE: "Перейти на главную страницу",
  ORDER_BOOK: "Книга ордеров",
  RECENT_TRADES: "Последние сделки",
  ADD_NOTE: "Добавьте на свою карточку персональную заметку",
  TIME: "Время",
  SPOT_PAIRS: "Спот пары",
  SIDE: "Направление",
  IBAN_TRANSACTIONS: "Транзакции IBAN",
  ACTIVE_STAKES: "Активные стейки",
  NEXT_REWARD_DATE: "Следующая дата вознаграждения",
};
export default ru;
